import React from "react";

export default ({ review = {} }) => {
  const { title, rating, subtitle, description } = review;
  const ratingComponent = rating.map((val, j) => {
    return <img key={j} src="/img/icons/star.png" alt="Etoile" width="22px" />;
  });
  return (
    <div className="slider__card ">
      <div className="slider-ratings-container-title-arrows">
        <div className="slider-ratings-container-title">
          <h5 className="slider-ratings-title">{title}</h5>
          <div>{ratingComponent}</div>
          <div className="slider-ratings-subtitle">{subtitle}</div>
        </div>
      </div>
      <p className="slider-ratings-body">{description}</p>
    </div>
  );
};
