const isBrowser = typeof window !== 'undefined'

export const clientRequestJotFormURL = isBrowser
	? window.location.host === 'dev.selfcity.fr'
		? 'https://form.jotform.com/210034768523351'
		: window.location.host.startsWith('localhost')
		? 'https://form.jotform.com/210746249158359'
		: 'https://form.jotform.com/210743403309043'
	: ''

export const openNewJotformTab = (token) => {
	if (isBrowser) {
		window.open(`${clientRequestJotFormURL}?token=${token}`, { target: '_blank' })
	}
}
