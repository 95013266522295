import React, { useState } from "react";
import "./slider.css";

export default ({ cards = [] }) => {
  const [index, setIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(null);
  const [isSlidingLeft, setIsSlidingLeft] = useState(false);
  const [isSlidingRight, setIsSlidingRight] = useState(false);

  const handleSlide = (direction) => {
    let defaultNextIndex = index;
    let defaultIsSlidingLeft = isSlidingLeft;
    let defaultIsSlidingRight = isSlidingRight;
    if (direction === "left") {
      defaultNextIndex = index + 1;
      defaultIsSlidingLeft = true;
    } else if (direction === "right" && index > 0) {
      defaultNextIndex = index - 1;
      defaultIsSlidingRight = true;
    } else {
      return;
    }

    setNextIndex(defaultNextIndex);
    setIsSlidingLeft(defaultIsSlidingLeft);
    setIsSlidingRight(defaultIsSlidingRight);

    setTimeout(() => {
      setIndex(defaultNextIndex);
      setNextIndex(null);
      setIsSlidingLeft(false);
      setIsSlidingRight(false);
    }, 500);
  };

  const handleSlideLeft = () => {
    handleSlide("left");
  };

  const handleSlideRight = () => {
    handleSlide("right");
  };

  const cardsLength = cards.length;

  return (
    <div className="slider-ratings">
      {/* Left Arrow */}
      <div
        className={
          (index === 0 ? "opacity-0" : "") +
          " slider-ratings-arrow  slider-ratings-arrow-left"
        }
        onClick={handleSlideRight}
      >
        <img
          src="/img/icons/next-arrow-green.svg"
          alt="Précédent"
          width="80px"
        />
      </div>

      {/* Ratings container */}
      <div className="slider-ratings-container">
        {cards.map((card, i) => {
          let hideAtLeft = false;
          if (isSlidingRight && i === nextIndex) {
            hideAtLeft = false;
          } else if (i < index) {
            hideAtLeft = true;
          }

          return (
            <div
              key={i}
              className={
                (hideAtLeft ? "hideAtLeft " : "") +
                (isSlidingLeft && index === i ? "slidingLeft sliding " : "") +
                (isSlidingRight && nextIndex === i
                  ? "slidingRight sliding "
                  : "") +
                (index === i ? "active " : "") +
                (nextIndex === i ? "nextActive " : "") +
                "  slider__card-container"
              }
            >
              {card}
            </div>
          );
        })}
      </div>
      {/* Right Arrow */}
      <div
        className={
          (index === cardsLength - 1 ? "opacity-0" : "") +
          " slider-ratings-arrow slider-ratings-arrow-right"
        }
        onClick={handleSlideLeft}
      >
        <img src="/img/icons/next-arrow-green.svg" alt="Suivant" width="80px" />
      </div>
    </div>
  );
};
