export default [
  {
    id: "LaTribune",
    href:
      "https://toulouse.latribune.fr/entreprises/2020-04-03/en-confinement-le-toulousain-selfcity-propose-le-plombier-en-visio-et-gratuit-844191.html",
    src: "/img/press/latribune_final.png",
    alt: "Selfcity La Tribune",
  },
  {
    id: "LesEchos",
    href:
      "https://business.lesechos.fr/entrepreneurs/idees-de-business/0602596975635-selfcity-veut-renouveler-le-metier-de-plombier-334602.php",
    src: "/img/press/lesEchos_final.png",
    alt: "Selfcity Les Echos",
  },
  {
    id: "LaDepeche",
    href:
      "https://www.ladepeche.fr/2020/04/07/selfcity-pratique-la-visio-plomberie-gratuite,8836001.php",
    src: "/img/press/ladepeche_final.png",
    alt: "Selfcity La Dépêche",
  },
  {
    id: "Actu",
    href:
      "https://actu.fr/occitanie/toulouse_31555/confinement-les-problemes-plomberie-cette-entreprise-toulouse-offre-une-aide-visio_32822885.html",
    src: "/img/press/actuToulouse_final.png",
    alt: "Selfcity Actu",
  },
  {
    id: "FranceInfo",
    href:
      "https://france3-regions.blog.francetvinfo.fr/numerique-midi-pyrenees/2020/04/27/coronavirus-des-startups-de-toulouse-vous-proposent-de-tester-gratuitement-leurs-innovations.html",
    src: "/img/press/france-info_x60.png",
    alt: "Selfcity France Info",
  },
];
