import React from "react";
import GAEventHelper from "../../utils/GAEventHelper";
import CONSTANTS from "../../utils/constants";

const RatingsAnchor = ({ text = "Voir tous les avis", className = "" }) => {
  return (
    <a
      href={CONSTANTS.GOOGLE_REVIEWS_URL}
      target="no_blank"
      className={className}
      onClick={(e) => {
        GAEventHelper.triggerClickEvent({
          category: CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.GOOGLE_MY_BUSINESS,
          action: CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.CLICK,
          label: null,
          options: {
            window: window ? window : null,
          },
        });
        return true;
      }}
    >
      {text}
    </a>
  );
};

export default RatingsAnchor;
