import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import CONSTANTS from "../../utils/constants";
const mapStyles = {
  width: "100%",
  height: "100%",
};

const selfcityPosition = {
  lat: 43.596424,
  lng: 1.422686,
};

export class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={15}
        style={mapStyles}
        initialCenter={{
          lat: 43.5966972,
          lng: 1.426018,
        }}
        disableDefaultUI={true}
      >
        <Marker name={"Selfcity"} position={selfcityPosition} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: CONSTANTS.GOOGLE_MAPS_API_KEY,
})(MapContainer);
